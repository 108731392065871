<!--
   Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 groceryee app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
 -->
<div class="header-top">
  <div class="tabs">

    <a [routerLink]="['/']" class="social">
      <img src="assets/logo.png" height="30" alt="" style="border-radius: 50%;">
    </a>
    <ng-template #popContent let-greeting="greeting">
      <div *ngFor="let item of dummy">
        <ngx-skeleton-loader animation="progress"
          [theme]="{ 'border-radius': '5px', height: '20px',width:'100px', border: '1px solid white' }">
        </ngx-skeleton-loader>
      </div>
      <div *ngFor="let item of cities" [ngClass]="id === item.id ? 'activeCity':'normalCity'" (click)="selected(item)">
        <mdb-icon fas icon="check-circle" *ngIf="id === item.id"></mdb-icon> {{item.name}}
      </div>
    </ng-template>

    <ng-template #popContent2 let-greeting="greeting">
      <div *ngFor="let item of dummyLang">
        <ngx-skeleton-loader animation="progress"
          [theme]="{ 'border-radius': '5px', height: '20px',width:'100px', border: '1px solid white' }">
        </ngx-skeleton-loader>
      </div>
      <div *ngFor="let item of langs" [ngClass]="lngId === item.file ? 'activeCity':'normalCity'"
        (click)="changed(item.file)">
        <img [src]="api.mediaURL+item.cover" style="height: 15px;width: 15px;margin-right: 5px;" alt=""> {{item.name}}
      </div>
    </ng-template>

    <a [ngbPopover]="popContent" triggers="manual" #p1="ngbPopover" (click)="p1.open()">
      <span class="fa fa-map-pin" aria-hidden="true" *ngIf="cityName"></span>
      {{cityName}}
      <span class="fa fa-chevron-down" aria-hidden="true" *ngIf="cityName"></span>
    </a>

    <a [ngbPopover]="popContent2" triggers="manual" #p2="ngbPopover" (click)="p2.open()">
      <img [src]="getLangFlag()" alt="" style="height: 15px;width: 15px;margin-right: 5px;">
      {{getLangName()}}
      <span class="fa fa-chevron-down" aria-hidden="true" *ngIf="getLangName()"></span>
    </a>

    <a [routerLink]="['/checkout']" class="social">
      <mdb-icon fas icon="shopping-cart"></mdb-icon>
      <span *ngIf="cart.cart?.length" class="cartTotal"> {{cart.cart?.length}} </span>
    </a>

  </div>
</div>
