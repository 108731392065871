/*
  Authors : initappz (Rahul Jograna)
  Website : https://initappz.com/
  App Name : ionic 5 groceryee app
  Created : 10-Sep-2020
  This App Template Source code is licensed as per the
  terms found in the Website https://initappz.com/license
  Copyright and Good Faith Purchasers © 2020-present initappz.
*/
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseURL: 'YOUR_BASE_URL/',
  // mediaURL: 'YOUR_BASE_URL/uploads/',
  // baseURL: 'https://vivaloca.sg/VivaLocaFoodDelivery/fooddelivery/App_code/API/index.php/',
  // mediaURL: 'https://vivaloca.sg/VivaLocaFoodDelivery/fooddelivery/App_code/API/index.php//uploads/',
  // mediaDisplayURL: 'https://vivaloca.sg/VivaLocaFoodDelivery/fooddelivery/App_code/API/uploads/',
  // onesignal: {
  //   appId: '872a73c6-18e4-4740-a14e-4c024a33d4f2',
  //   googleProjectNumber: 'GOOGLE_PROJECT_NUMBER',
  //   restKey: 'YjE4NmIwMzUtNTQwOC00ZjNlLTg2NzEtMGJlODIwN2I3NDc1'
  // },
  baseURL:
  "https://vivaloca.sg/VivaLocaFoodDelivery/fooddelivery/App_code/API/index.php/",
mediaURL:
  "https://vivaloca.sg/VivaLocaFoodDelivery/fooddelivery/App_code/API/index.php/uploads/",
mediaDisplayURL:
  "https://vivaloca.sg/VivaLocaFoodDelivery/fooddelivery/App_code/API/uploads/",
//   baseURL:
//   "http://13.229.66.12/VivaLocaFoodDelivery/fooddelivery/App_code/API/index.php/",
// mediaURL:
//   "http://13.229.66.12/VivaLocaFoodDelivery/fooddelivery/App_code/API/index.php/uploads/",
// mediaDisplayURL:
//   "https://vivaloca.sg/VivaLocaFoodDelivery/fooddelivery/App_code/API/uploads/",
onesignal: {
  appId: "872a73c6-18e4-4740-a14e-4c024a33d4f2",
  googleProjectNumber: "GOOGLE_PROJECT_NUMBER",
  restKey: "YjE4NmIwMzUtNTQwOC00ZjNlLTg2NzEtMGJlODIwN2I3NDc1",
},
onesignalStore: {
  appId: "455779a0-eb35-4449-a887-d1816ef26f10",
  googleProjectNumber: "GOOGLE_PROJECT_NUMBER",
  restKey: "Njk2MmNlYWYtMzc2OC00YjQzLThjMjktYTFjNmM4ZWI3Mjg0",
},
  general: {
    symbol: '$',
    code: 'USD'
  },
  
  authToken: '123456789'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
